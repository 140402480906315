.spinner-loading {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.spinner-dot-stick,
.spinner-double-dot-stick,
.spinner-double-dot-in {
  display: block;
  float: left;
  width: 120px;
  height: 120px;
  border-radius: 50%;
  border: 4.125px solid rgba(50, 41, 41, 0.2);
  animation: spinner 1.2s linear infinite;
}

@keyframes spinner {
  0% {
    transform: rotate(0);
  }
  100% {
    transform: rotate(360deg);
  }
}
/* Dots */
.spinner-dot-stick,
.spinner-double-dot-stick,
.spinner-double-dot-in {
  position: relative;
}

.spinner-dot-stick:before,
.spinner-double-dot-stick:before,
.spinner-dot-stick:after,
.spinner-double-dot-stick:after,
.spinner-double-dot-in:before,
.spinner-double-dot-in:after {
  content: "";
  position: absolute;
  top: 6.25px;
  left: 35.875px;
  width: 12.25px;
  height: 12.25px;
  border-radius: 50%;
  background: #20623b;
}

.spinner-dot-stick:after,
.spinner-double-dot-stick:after,
.spinner-double-dot-in:after {
  top: auto;
  bottom: 6.25px;
}

.spinner-dot-stick:before,
.spinner-dot-stick:after,
.spinner-double-dot-stick:before,
.spinner-double-dot-stick:after {
  top: -6.25px;
}

.spinner-dot-stick:after,
.spinner-double-dot-stick:after {
  top: auto;
  bottom: -6.25px;
}

.spinner-dot-stick:after {
  display: none;
}

mat-form-field.my-form-field-color {
  color: #20623b;
}

mat-form-field .mat-form-field-wrapper .mat-form-field-flex {
  background-color: white;
}

mat-form-field.mat-focused .mat-form-field-underline .mat-form-field-ripple {
  background-color: #20623b;
}

mat-form-field.mat-focused .mat-form-field-infix .mat-form-field-label {
  color: #20623b;
}

html, body {
  height: 100%;
}

body {
  margin: 0;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

html,
body {
  height: 100%;
}

body {
  margin: 0;
  overflow-x: hidden;
  font-family: Roboto, "Helvetica Neue", sans-serif;
}

@media (max-width: 400px) {
  .mat-expansion-panel-body {
    padding: 0 !important;
  }

  .btn-container {
    position: relative !important;
    top: 2.5em !important;
    bottom: 1.5em !important;
  }
}
@media (max-width: 769px) {
  .progress-container {
    padding: 1em 2em !important;
  }

  .mat-checkbox-label {
    white-space: normal;
  }

  .mat-expansion-panel-body {
    display: flow-root;
    margin-left: 2em;
  }

  .btn-container {
    position: relative !important;
    top: 2.5em !important;
    bottom: 1.5em !important;
  }
}
.overlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 2;
}

.btn-container {
  display: flex;
  width: 100%;
  bottom: 4em;
  justify-content: center;
}

.btn-basic {
  width: 20em;
  height: 3.5em;
  border-radius: 35px;
  font-size: 16px;
  font-weight: 500;
  border: transparent;
  margin-top: 20px;
  margin-bottom: 20px;
}

.btn-submit {
  background: #20623b;
  color: white;
  cursor: pointer;
}

.btn-disabled {
  background: #f3f3f3;
  color: gray;
}

.back-btn-container {
  display: flex;
}

.back-written {
  font-size: 15px;
  position: relative;
  top: 4px;
  color: #20623b;
  margin-left: -7px;
  cursor: pointer;
}

.btn-summary {
  height: 2em;
  border-radius: 15px;
  background: white;
  border: transparent;
  margin-left: 1em;
  margin-right: -7px;
}

.title-container {
  display: flex;
  justify-content: center;
  color: #20623b;
  font-weight: bold;
}

.intestazione-container {
  display: flex;
  justify-content: center;
}

.image-footer {
  max-width: 150px;
  max-height: 100px;
}

.progress-container {
  padding: 2em 4em;
}

mat-progress-bar {
  border-radius: 25px;
  height: 9px !important;
}

.mat-progress-bar-fill::after {
  background-color: #20623b;
}

.mat-progress-bar-buffer {
  background: #e4e8eb !important;
}

.btn-mobile {
  background: white;
  border: transparent;
}

mat-icon {
  color: #20623b;
  font-size: 3em;
  margin-left: -20px;
}

.mat-calendar-period-button {
  display: none !important;
}

.form-control:disabled {
  background-color: inherit;
}